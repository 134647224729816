import React, { useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  //   Link,
  // Text,
  // Grid,
  // Image,
  // List,
  // Button,
  // ListItem,
  // ListIcon,
  useColorModeValue,
} from '@chakra-ui/react'
import { InsightsContent } from '../data'
import SEO from '../components/seo'
import {
  InsightsCard,
  fontSizes,
} from '../components/UtilityComponents'
const InsightsPage = () => {
  const [expandAll, setExpandAll] = useState(false)

  const fontColorHeading = useColorModeValue('brand.900', 'brand.1')
  // const backgroundColor = useColorModeValue('brand.100', 'brand.600')
  // const fontColor = useColorModeValue('brand.900', 'brand.1')
  // const fontColorSubHeading = useColorModeValue(
  //   'brand.700',
  //   'brand.100'
  // )
  // const fontColorListItem = useColorModeValue(
  //   'brand.600',
  //   'brand.200'
  // )
  return (
    <>
      <SEO
        title="Insights"
        keywords={InsightsContent.map(insight => insight.name)}
      />
      <Box>
        <Flex
          margin={'auto'}
          paddingY={[10]}
          paddingX={[3, 5, 10]}
          maxWidth="1400px"
          flexDirection={['column']}
          marginTop={[5]}
          // justifyContent={['space-between', 'space-evenly']}
          // alignItems={['center', 'center', 'center', 'center']}
          borderColor="yellow.900"
          borderWidth={0}
        >
          <Heading
            letterSpacing="wide"
            // textAlign={['center', 'left']}
            marginBottom={[5]}
            fontWeight="bold"
            textTransform={'uppercase'}
            // lineHeight="tall"
            maxW={['600px']}
            fontFamily="heading"
            color={fontColorHeading}
            fontSize={fontSizes.heading}
          >
            Insights
          </Heading>
          {/* <Text
          backgroundColor={'brand.400'}
          color={'brand.50'}
          padding={2}
          fontSize={fontSizes.helperText}
          alignSelf={'flex-end'}
          marginBottom={3}
          onClick={() => setExpandAll(!expandAll)}
        >
          {expandAll ? 'Collapse All' : 'Expand All'}
        </Text> */}
          <Flex
            flexDirection="column"
            //   flexWrap="wrap"
            justifyContent="space-between"
          >
            {InsightsContent.map((insight, index) => (
              <InsightsCard
                key={index}
                expandAll={expandAll}
                name={insight.name}
                description={insight.description}
              />
            ))}
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
export default InsightsPage
